<template>
    <div>
        <div class="div-crud-sistema" style="overflow-x: auto;">
            <div class="row">
                <div class="col" style="text-align: center;font-weight: bold;">
                    FILTRAR WEBHOOKS
                </div>
            </div>            
            <form>
                <div class="row" style="padding:15px;">
                    <div class="col-md-2">
                        <label for="WebHook">WebHook ID</label>
                        <input type="text" class="form-control" id="WebHook" placeholder="WebHook ID" v-model="filtro.webhook_id">
                    </div>
                    <div class="col-md-2">
                        <label for="idcontato">ID Contato CRM</label>
                        <input type="text" class="form-control" id="idcontato" placeholder="ID Contato CRM" v-model="filtro.contatoid">
                    </div>
                    <div class="col-md-3">
                        <label for="inscrito">Inscrito</label>
                        <input type="text" class="form-control" id="inscrito" placeholder="Nome do Inscrito" v-model="filtro.nome_inscrito">
                    </div>
                    <div class="col-md-3">
                        <label for="tipowebhook">Tipo de WebHook</label>
                        <select type="text" class="form-control" id="tipowebhook" placeholder="ID CRM" v-model="filtro.tipoeventoaviso">
                            <option :value="0">SELECIONE</option>
                            <option value="c">Inscrição Confirmada</option>
                            <option value="m">Matriculado</option>
                            <option value="a">Contrato Aceito</option>
                            <option value="f">Atualização Cadastral</option>
                            <option value="v">Enviar Valor Matrícula CRM</option>
                            <option value="d">Documentos</option>
                            <!-- <option value="EnviaMatriculaCrm">Enviar Nº de Matrícula CRM</option> -->
                            <!-- <option value="f">Atualização Cadastral</option> -->
                        </select>
                    </div>
                    <div class="col-md-2">
                        <label for="sincronizado">Sincronizados</label>
                        <select type="text" class="form-control" id="sincronizado" placeholder="ID CRM" v-model="filtro.sincronizado">
                            <option :value="null">SELECIONE</option>
                            <option :value="1">Sim</option>
                            <option :value="0">Não</option>
                        </select>
                    </div>
                    <div class="col-md-2" style="padding:15px;">
                        <button class="btn btn-primary btn-sm" @click.prevent="searchWebHooksUnity(1)">Filtrar</button>&nbsp;&nbsp;<button class="btn btn-primary btn-sm" @click.prevent="getWebHooksUnity()">Todos</button>
                    </div>
                </div>
            </form>
                <br>
            <div class="row" >
                <div class="col">
                    <table class="table table-hover table-sm" style="white-space: normal;border-top: 0px;width:100%;">
                        <thead>
                            <tr style="background-color: #1c4d5a;color:white;">
                                <th style="text-align: center;">
                                    Ações
                                </th>
                                <!-- <th style="text-align: center;width: auto;">
                                    Item
                                </th> -->
                                <th style="text-align: center;max-width: 120px;width: 120px;">
                                    ID
                                </th>
                                <th style="text-align: center;width: auto;">
                                    Notificações
                                </th>
                                <th style="text-align: center;max-width: 250px;width: 250px;">
                                    Nome
                                </th>
                                <!--th style="text-align: center;max-width: 120px;width: 120px;">
                                    Curso
                                </th-->
                                <th colspan="2" style="text-align: center;max-width: 350px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                                    Turma ERP
                                </th>
                                <!-- <th style="text-align: center;text-overflow: ellipsis;">
                                    Requisição ERP
                                </th> -->
                                <th style="text-align: center;text-overflow: ellipsis;width:10px;overflow: hidden;white-space: nowrap;">
                                    Contato ID CRM
                                </th>
                                <!-- <th style="text-align: center;width: auto;">
                                    Reg. CRM
                                </th> -->
                                <th style="text-align: center;width: 45px;">
                                    Sinc.
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Data
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Atualização
                                </th>
                                <!-- <th style="text-align: center;width: 100px;">
                                    Status
                                </th> -->
                                <th style="text-align: center;width: 100px;">
                                    Status
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Origem
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Tipo
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody v-if="lista_web_hook.data && lista_web_hook.data.length>0">
                            <template v-for="aluno_captado,item in lista_web_hook.data" >
                                <tr :key="item">
                                    <td style="text-align: center;white-space: nowrap;width:180px!important;max-width:200px!important;" class="align-middle">
                                        <a v-if="aluno_captado.turma_deleted_at==null" :href="'/Access/Unity/Unitie/'+unitie.id+'/captacao/wh/'+aluno_captado.id" class="btn btn-primary btn-sm" style="width: 40px;"><i class="fa-sharp fa-solid fa-eye fa-xl"></i></a>
                                        <a v-else :href="'/Access/Unity/Unitie/'+unitie.id+'/captacao/wh/'+aluno_captado.id" class="btn btn-primary btn-sm disabled" style="width: 40px;"><i class="fa-sharp fa-solid fa-eye fa-xl"></i></a>
                                        <button v-if="aluno_captado.processado==1 && aluno_captado.turma_deleted_at==null" class="btn btn-primary btn-sm" style="width: 40px;" @click.prevent="modalReprocessamentoWebHook(aluno_captado)"><i class="fa-solid fa-arrows-rotate fa-rotate-90"></i></button>
                                        <button v-else class="btn btn-primary btn-sm" style="width: 40px;" disabled><i class="fa-solid fa-arrows-rotate fa-rotate-90"></i></button>
                                        <button v-if="aluno_captado.processado==0 && aluno_captado.tipoeventoaviso=='c' && aluno_captado.tipoeventoaviso=='c' && aluno_captado.enviado_job==0 && aluno_captado.processado==0 && aluno_captado.turma_deleted_at==null" class="btn btn-primary btn-sm" style="width: 40px;" @click.prevent="enviaParaFilaTrabalhos(aluno_captado)"><i class="fa-regular fa-paper-plane"></i></button>
                                        <button v-else class="btn btn-primary btn-sm" style="width: 40px;" disabled><i class="fa-regular fa-paper-plane"></i></button>
                                        <button v-if="usuarioLogado.perfil_usuario==1" class="btn btn-primary btn-sm" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;"><b-icon icon="trash-fill" font-scale="1.3" @click.prevent="MensagemExcluirWebHook(aluno_captado)"></b-icon></button>
                                    </td>
                                    <!-- <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ item+1 }}
                                    </td> -->
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ aluno_captado.id }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        <a :href="'/Access/Unity/Unitie/'+unitie.id+'/notificacoes/webhook/'+aluno_captado.id" class="btn btn-sm btn-primary" target="_blank">
                                            Notas <span :class="'badge badge-'+(aluno_captado.qtd_notificacoes_nao_lidas>0 ? 'danger' : 'primary')">&nbsp;{{ aluno_captado.qtd_notificacoes_nao_lidas }}&nbsp;</span>
                                        </a>
                                    </td>
                                    <td class="align-middle" style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:120px;overflow: hidden;white-space: nowrap;">
                                        {{ aluno_captado.nomeinscrito }}
                                    </td>
                                    
                                    <td class="align-middle" style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:200px;overflow: hidden;white-space: nowrap;">
                                        {{ aluno_captado.descricaocompleta }}
                                    </td>
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b-icon v-if="aluno_captado.turma_deleted_at==null" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td>
                                    <!-- <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{aluno_captado.requisicao_id}}
                                    </td> -->
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ aluno_captado.contatoid }}
                                    </td>
                                    <!-- <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ aluno_captado.registroprocesso }}
                                    </td> -->
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b-icon v-if="aluno_captado.processado == 1" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td>
                                    <td style="text-align: center;white-space: nowrap;" class="align-middle">
                                        {{  daraHoraBrasileiro(aluno_captado.created_at) }}
                                    </td>
                                    <td style="text-align: center;white-space: nowrap;" class="align-middle">
                                        {{  daraHoraBrasileiro(aluno_captado.updated_at) }}
                                    </td>
                                    <!-- <td :class="'align-middle ' + getEstyloStatusRequisicao(aluno_captado)" style=";white-space: nowrap;padding:5px!important;font-size: 11px!important;text-align: center; width: auto!important;border-radius: 0px;">
                                        
                                            <b>{{ getEstyloDescricaoStatusRequisicao(aluno_captado) }}</b>
                                    </td> -->
                                    <td :class="'align-middle ' + getEstyloStatusInscricao(aluno_captado)" style="white-space: nowrap;padding:5px!important;font-size: 11px!important;text-align: center; width: auto!important;border-radius: 0px;">
                                        <b>{{ getDescricaoStatusRequisicao(aluno_captado) }}</b>
                                    </td>
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b>{{ aluno_captado.origem_inclusao }}</b>
                                    </td>
                                    <td style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:125px;overflow: hidden;white-space: nowrap;" class="align-middle">
                                        <b>{{ ( aluno_captado.tipoeventoaviso=='c' ? "Insc. Confirmada" : ( aluno_captado.tipoeventoaviso=='a' ? "Contrato Assinado" : ( aluno_captado.tipoeventoaviso=='m' ? "Matriculado":( aluno_captado.tipoeventoaviso=='v' ? "Envia Valor CRM":( aluno_captado.tipoeventoaviso=='f' ? "Atualização Cadastral":( aluno_captado.tipoeventoaviso=='d' ? "Documentos":"Outros" ) ) ) ) ) ) }}</b>
                                    </td>
                                </tr>

                            </template>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="12" style="text-align: center;font-weight: bold;">
                                    Não existem dados para exibir
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="13" style="text-align: center;background-color: #1c4d5a;color:white;" class="align-middle">
                                    <paginate class="padding text-center">
                                        <li v-for=" link, key in lista_web_hook.links" :key="key" :class="(link.active ? 'page-item active':(link.url ? 'page-item':'page-item disabled'))" style="cursor:pointer;">
                                            <a class="page-link" tabindex="-1" @click="paginacaoWebHooks(link)" style="border-color: #fff!important;">{{ (key==0 ? "<<" :(key==lista_web_hook.links.length-1 ? ">>": link.label ) ) }}        </a>
                                        </li>
                                    </paginate>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
        <loading id="GetDadosWebHooks" titulo="Obtendo WebHooks do Sistema, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <loading :id="'EnviaWebHookProcessamento'+unitie.id" titulo="Enviando WebHook para reprocessamento, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <loading :id="'EnviaWebHookProcessamentoJob'+unitie.id" titulo="Enviando WebHook para fila de Jobs, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <loading :id="'ExcluindoWebHooks'+unitie.id" titulo="Excluindo WebHook, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
    </div>
</template>

<script>
export default {
    props:['unitie','usuarioLogado','filtros'],
    data(){
        return{
            captados_webhook:[],
            lista_web_hook:[],
            web_hook_assinados:[],
            web_hook_matriculados:[],
            web_hook_valormatricula:[],
            filtro:{
                tipoeventoaviso:0,
                nome_inscrito:null,
                contatoid:null,
                webhook_id:null,
                sincronizado:null
            },
            url_webhooks:'/api/v1/Crm/getWebHooksUnity/',
            url_search:'/api/v1/Crm/searchWebHooksUnity/',
            complemento_url:"",
        }
    },
    methods:{
        enviaParaFilaTrabalhos(web_hook){
            var resposta = confirm("Você realmente deseja enviar este WebHook para a fila de Jobs?\nSe sim, após clicar no OK aguarde outra Mensagem Informando o Sucesso do Envio para Fila de Jobs");

            if(resposta == true){
                this.sendWebhookJob(web_hook);
            }
        },
        modalReprocessamentoWebHook(web_hook){
            var resposta = confirm("Você realmente deseja enviar este WebHook para reprocessamento?");

            if(resposta == true){
                this.reprocessWebhook(web_hook);
                //window.alert("WebHook Enviado para Reprocessamento com sucesso")
            }
        },
        sendWebhookJob(web_hook){
                $('#EnviaWebHookProcessamentoJob'+this.unitie.id).modal('show');
                let url='/api/v1/Crm/Unity/'+this.unitie.id+'/sendWebHookJob/webhook'                             
                let formData = new FormData();
                formData.append('_method','post');
                formData.append('web_hook', web_hook.id)
                let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
                axios.post(url, formData,configuracao)
                    .then(response => {
                        console.log('response.data.webhook',response.data)

                        if(response.data.erro==false){
                            web_hook.enviado_job = 1;
                            $('#EnviaWebHookProcessamentoJob'+this.unitie.id).modal('hide');
                            window.alert(response.data.mensagem)
                        }
                        
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response.data)
                        $('#EnviaWebHookProcessamentoJob'+this.unitie.id).modal('hide');
                        window.alert('houve um erro ao enviar o webhook para reprocessamento')

                    })

                    
                    
            // }
        },
        reprocessWebhook(web_hook){
                $('#EnviaWebHookProcessamento'+this.unitie.id).modal('show');
                let url='/api/v1/Crm/Unity/'+this.unitie.id+'/resend/webhook'                             
                let formData = new FormData();
                formData.append('_method','post');
                formData.append('web_hook', web_hook.id)
                let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
                axios.post(url, formData,configuracao)
                    .then(response => {
                        console.log(response.data)

                        if(response.data.erro==false){
                            //this.SemProcessamentoTotal = response.data.total
                            $('#EnviaWebHookProcessamento'+this.unitie.id).modal('hide');
                            this.getWebHooksUnity();
                            
                            window.alert(response.data.mensagem)
                        }
                        
                    })
                    .catch(errors => {
                        this.errors = errors.response.data.errors
                        console.log(errors.response.data)
                        $('#EnviaWebHookProcessamento'+this.unitie.id).modal('hide');
                        window.alert('houve um erro ao enviar o webhook para reprocessamento')

                    })

                    
                    
            // }
        },
        MensagemExcluirWebHook(web_hook){
            var resposta = confirm("Você realmente deseja Excluir este WebHook?\nSe sim, após clicar no OK aguarde outra Mensagem Informando o Sucesso da Exclusão do WebHook");

            if(resposta == true){
                this.excluirWebHook(web_hook);
            }
        },
        excluirWebHook(web_hook){
            
            $('#ExcluindoWebHooks'+this.unitie.id).modal('show');
            console.log('id',web_hook.id)
            var config = {
            method: 'delete',
            url: '/api/v1/Crm/webhook/'+web_hook.id,
            // headers: {
            //         'Accept': 'application/json',
            //         'Authorization': this.$root.token(),
            //     },
            };
            //executa requisição
            axios(config)
            .then(response => {
                console.log(response);
                if(response.data.error==false){
                   
                   window.alert(response.data.mensagem)
                }else{
                    window.alert(response.data.mensagem)
                }
                $('#ExcluindoWebHooks'+this.unitie.id).modal('hide');
            })
            .catch(function (error) {
                window.alert(error)
                $('#ExcluindoWebHooks'+this.unitie.id).modal('hide');
            });

            this.filtrarAposExcluir();
        },
        /*getEstyloStatusInscricao(web_hook){
            var status = (web_hook.tipoeventoaviso=='c' && web_hook.status_inscricao!=null ?
                    web_hook.status_inscricao : (web_hook.tipoeventoaviso=='a' && web_hook.status_post_contrato!=null ? 
                        web_hook.status_post_contrato  : (web_hook.tipoeventoaviso=='a' && web_hook.status_inscricao!=null ? 
                            web_hook.status_inscricao : (web_hook.tipoeventoaviso=='m' && web_hook.status_inscricao!=null ? 
                                web_hook.status_inscricao : (web_hook.tipoeventoaviso=='v' && web_hook.status_valormatricula!=null ? 
                                    web_hook.status_valormatricula : web_hook.status
                                )
                            )
                        )
                    )
                )
                console.log(status,web_hook.id,web_hook.tipoeventoaviso);
            if(web_hook.processado == 1 && status=='success' && web_hook.requisicao_id>0){
                return 'alert alert-success text-center'
            }else if (web_hook.processado == 1 && status=='error' && web_hook.requisicao_id>0){
                return 'alert alert-danger text-center'
            }else if(web_hook.processado==1 && status=='pending'){
                return 'alert alert-primary text-center'
            }
        },*/
        getEstyloStatusInscricao(web_hook){
            if(web_hook.processado == 1 && ( ( (web_hook.status_inscricao=='success' || web_hook.status_post_contrato =='success' || web_hook.status_valormatricula=='success') && web_hook.requisicao_id>0 ) || (web_hook.status_atualizacao_cadastral=='success') || (web_hook.status=='success'))){
                return 'alert alert-success text-center'
            }else if (web_hook.processado == 1 && web_hook.status_inscricao=='error' && web_hook.requisicao_id>0){
                return 'alert alert-danger text-center'
            }else if(web_hook.processado==1 && web_hook.status_inscricao=='pending'){
                return 'alert alert-primary text-center'
            }
        },
        getEstyloStatusRequisicao(web_hook){
            if(web_hook.status=='success' && web_hook.requisicao_id>0){
                return 'alert alert-success text-center'
            }else if (web_hook.requisicao_id>0){
                return 'alert alert-danger text-center'
            }else if(web_hook.processado==1 && web_hook.status!='success' && web_hook.status!='Não Foi possível obter dados da Oportunidade no CRM.'){
                return 'alert alert-primary text-center'
            }else if(web_hook.processado==0 && web_hook.status=='Reprocessar WebHook'){
                return 'alert alert-warning text-center text-danger bg-warning'
            }else if(web_hook.processado==1 && web_hook.status=='Não Foi possível obter dados da Oportunidade no CRM.'){
                return 'alert alert-danger text-center'
            }
        },
        getEstyloDescricaoStatusRequisicao(aluno_captado){
            var status = (aluno_captado.tipoeventoaviso=='c' && aluno_captado.status_inscricao!=null ?
                    aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='a' && aluno_captado.status_post_contrato!=null ? 
                        aluno_captado.status_post_contrato  : (aluno_captado.tipoeventoaviso=='a' && aluno_captado.status_inscricao!=null ? 
                            aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='m' && aluno_captado.status_inscricao!=null ? 
                                aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='v' && aluno_captado.status_valormatricula!=null ? 
                                    aluno_captado.status_valormatricula : aluno_captado.status
                                )
                            )
                        )
                    )
                )

            
            if(status==null || status==''){
                return status;
            }else if(status=='success' || status==2){
                return 'SUCESSO'
            }else if (status=='error' || status==3){
                return 'ERRO'
            }else if(status=='pending' || status==1){
                return 'PENDENTE'
            }else if(status=='warning' || status==4){
                return 'AVISO'
            }else {
                return status
            }
        },
        getDescricaoStatusRequisicao(aluno_captado){
            var status = (aluno_captado.tipoeventoaviso=='c' && aluno_captado.status_inscricao!=null ?
                    aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='a' && aluno_captado.status_post_contrato!=null ? 
                        aluno_captado.status_post_contrato  : (aluno_captado.tipoeventoaviso=='a' && aluno_captado.status_inscricao!=null ? 
                            aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='m' && aluno_captado.status_inscricao!=null ? 
                                aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='v' && aluno_captado.status_valormatricula!=null ? 
                                    aluno_captado.status_valormatricula : (aluno_captado.tipoeventoaviso=='f' && aluno_captado.status_atualizacao_cadastral!=null ? 
                                        aluno_captado.status_atualizacao_cadastral : (aluno_captado.tipoeventoaviso=='d' && aluno_captado.status!=null ? 
                                            aluno_captado.status : aluno_captado.status
                                        )
                                        
                                    )
                                )
                            )
                        )
                    )
                )

            console.log(status,aluno_captado.id,aluno_captado.tipoeventoaviso);
            if(status==null || status==''){
                return status;
            }else if(status=='success' || status==2){
                return 'SUCESSO'
            }else if (status=='error' || status==3){
                return 'ERRO'
            }else if(status=='pending' || status==1){
                return 'PENDENTE'
            }else if(status=='warning' || status==4){
                return 'AVISO'
            }else {
                return status
            }
        },
        paginacaoWebHooks(link){

            var arrPage = link.url.split("page");

            var page = (arrPage.length > 0 ? arrPage[arrPage.length-1].split("=") : 0 )
            
            this.getFiltrosComplementares(page[page.length - 1])

            console.log("link",page[page.length - 1])
            console.log("Paginacao",this.complemento_url)
            var testeUrl = this.complemento_url.split("=")
            if(this.complemento_url!=null && this.complemento_url!=""){
                
                this.searchWebHooksUnity(0);
                console.log("Aqui 1")

            }else if(link.url){
                
                //this.url_webhooks = link.url; // ajustando a url com parâmetros de pagina
                
                //  console.log(this.urlUnidades)
                this.url_webhooks = '/api/v1/Crm/getWebHooksUnity/'+this.unitie.id + (page.length>0 && page[page.length - 1] > 1 ? "?page="+page[page.length - 1]:"")
                this.getWebHooksUnity();
                console.log("Aqui 2")
                // console.log('paginacaoWebHooks',link.url)
            }

        },        
        converteJsonObjeto(dados){
            
            //var x = JSON.stringify(dados.substring(1,dados.length-1),null,'\t');
            var a = JSON.parse(dados)
            // console.log('a',a)
            return a
        },
        daraHoraBrasileiro(date){
            let dataHora = new Date(date)
            // let dataHoraUtc = new Date()
            return dataHora.toLocaleString('pt-BR', { timezone: 'UTC' })
        },
        formatDate(date,idioma) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            if(idioma==1){
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }else if(idioma==2){
                let dt = [day, month, year].join('/');
                if(dt=='31/12/1969'){
                    return null
                }else{
                    return dt;
                }
            }else{
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }
        },
        getWebHooksUnity(){
            console.log(this.unitie);
            $('#GetDadosWebHooks').modal('show');
            //this.url_webhooks = this.url_webhooks+this.unitie.id

            console.log('URL: ' + this.paginacaoWebHooks)
            var config = {
                method: 'get',
                url: this.url_webhooks,
                // headers: {
                //         'Accept': 'application/json',
                //         'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                    this.lista_web_hook = response.data.webhooks
                    // console.log('lista_web_hook',this.lista_web_hook)
                    $('#GetDadosWebHooks').modal('hide');
                })
                .catch(function (error) {
                    // console.log('error',error);
                    window.alert('houve um erro no processamento da inscrição')
                    $('#GetDadosWebHooks').modal('hide');
                    //this.erro = error
                    // console.log(error);
                });
                
                
        
        },
        getFiltrosComplementares(pagina = 0){
            this.complemento_url = "";

            if(this.filtro.contatoid!=null && this.filtro.contatoid>0){
                this.complemento_url = this.complemento_url + "&contatoid="+""+this.filtro.contatoid+""
            }else{
                this.complemento_url = this.complemento_url
            }

            if(this.filtro.webhook_id!=null && this.filtro.webhook_id>0){
                this.complemento_url = this.complemento_url + "&webhook_id="+""+this.filtro.webhook_id+""
            }else{
                this.complemento_url = this.complemento_url
            }

            if(this.filtro.nome_inscrito!=null && this.filtro.nome_inscrito!=""){
                this.complemento_url = this.complemento_url + "&nome_inscrito="+""+this.filtro.nome_inscrito+""
            }else{
                this.complemento_url = this.complemento_url
            }

            if(this.filtro.tipoeventoaviso!=0 && this.filtro.tipoeventoaviso!=""){
                this.complemento_url = this.complemento_url + "&tipoeventoaviso="+""+this.filtro.tipoeventoaviso+""
            }else{
                this.complemento_url = this.complemento_url
            }

            if((this.filtro.sincronizado==0 || this.filtro.sincronizado==1) && this.filtro.sincronizado!=null){
                this.complemento_url = this.complemento_url + "&sincronizado="+this.filtro.sincronizado
            }else{
                this.complemento_url = this.complemento_url + "";
            }

            this.complemento_url=(this.complemento_url!="" ? "?"+this.complemento_url.substring(1, this.complemento_url.len):"")
            this.complemento_url = (pagina > 0  && this.complemento_url!=""? this.complemento_url+"&page="+pagina : this.complemento_url)
            return this.complemento_url
        },
        searchWebHooksUnity(getfiltro = 0){
            $('#GetDadosWebHooks').modal('show');
            //this.url_webhooks = this.url_webhooks+this.unitie.id
            
            if (getfiltro==1){

                this.getFiltrosComplementares();

            }
            console.log('this.complemento_url',this.complemento_url);
            console.log('this.url_search',this.url_search + this.complemento_url);


            
            
            var config = {
                method: 'get',
                url: this.url_search + this.complemento_url,
                // headers: {
                //         'Accept': 'application/json',
                //         'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                    this.lista_web_hook = response.data.webhooks
                    // console.log('lista_web_hook',this.lista_web_hook)
                    $('#GetDadosWebHooks').modal('hide');
                })
                .catch(function (error) {
                    // console.log('error',error);
                    window.alert('houve um erro no processamento da inscrição')
                    $('#GetDadosWebHooks').modal('hide');
                    //this.erro = error
                    // console.log(error);
                });
                
                
        
        },
        filtrarAposExcluir(){
            if(this.status_processamento == "SUCESSO"){
                
                this.filtraWebHooksStatus("success")

            }else if(this.status_processamento == "ERRO"){
                
                this.filtraWebHooksStatus("error")

            }else if(this.status_processamento == "PENDENTE"){
                
                this.filtraWebHooksStatus("pending")

            }else{
                this.filtraWebHooksStatus("aguardando")
            }
        }
        
    },
    created(){
        // this.captados_webhook = this.webHooks;
        // console.log(this.captados_webhook);
        this.url_webhooks = this.url_webhooks+this.unitie.id
        this.url_search = this.url_search+this.unitie.id

        if(this.filtros.inscrito){

            this.filtro.nome_inscrito =  this.filtros.inscrito
        
        }
        if(this.filtros.WebHook){

            this.filtro.webhook_id =  this.filtros.WebHook
        }

        if(this.filtros.idcontato){

            this.filtro.contatoid =  this.filtros.idcontato
        }

        if(this.filtros.tipowebhook){

            this.filtro.tipoeventoaviso =  this.filtros.tipowebhook
        }

        if(this.filtros.sincronizado){

            this.filtro.sincronizado =  this.filtros.sincronizado
        }
    },
    mounted(){
       
       if((this.filtros.inscrito && this.filtros.inscrito!='') || (this.filtros.WebHook && this.filtros.WebHook!='') || (this.filtros.idcontato && this.filtros.idcontato!='') || (this.filtros.tipowebhook && this.filtros.tipowebhook!='') || (this.filtros.sincronizado && (this.filtros.sincronizado==1 || this.filtros.sincronizado==0))){
            this.searchWebHooksUnity(1);
       }else{
            this.getWebHooksUnity();
       }
       
    }
}
</script>

<style scoped>
    .div-crud-sistema{
            background: white;
            padding: 0px;
            padding-top: 10px;
            border-radius: 0px 0px 10px 10px;
            
    }

    .nav-item{
        
        text-align: center;
        border-radius: 10px 10px 10px 10px!important;
    }

    .tab-pane{
        overflow: auto;
        width: 100%;
    }
    .div-crud-sistema > .col-2 > .nav-pills > .nav-link,
    .div-crud-sistema > .nav > .nav-item > .nav-link{
        color:black!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .div-crud-sistema > .row > .col-2 > .nav-pills > .active,
    .div-crud-sistema > .nav > .nav-item > .active{
        color:white!important;
        background-color:#1c4d5a!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .tab-content > .active {
        background-color:#fff!important;
        border: 1px solid #1c4d5a;
    }

    #td-html > p {
        margin:0px!important;
    }

    .nav-tabs {
        border-bottom: 5px solid #1c4d5a;
    }

    .table thead th {
        vertical-align: bottom;
        border: 0px solid #1c4d5a;
    }

    p{
        margin-bottom:0px;
        max-width:50px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .btn-primary.disabled{
        background-color: #5d7773!important;

        border-color: #5d7773!important;
    }
</style>
